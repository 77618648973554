import React, {useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
// import Search from "../../Components/Search/Search";
import Footer from "../../Components/Footer/Footer";
import SearchBanner from "../../Components/Search/SearchBanner/SearchBanner";
import SearchResults from "../../Components/Search/SearchResults/SearchResults";

const SearchPage = () => {

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100)
    })
  }, [])

  return (
    <React.Fragment>
      <div className={scroll ? "header-search menu-scroll" : "header-search"}><Header/></div>
      <SearchBanner/>
      <SearchResults/>
      <div className="search-footer"><Footer/></div>
    </React.Fragment>
    );
};

export default SearchPage;
