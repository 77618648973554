import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./SearchResults.scss";

import PropertyListImg1Desktop from "../../../images/Search/propertysearchdesk1.jpg";
import PropertyListImg1Tab from "../../../images/Search/propertysearchdesk1.jpg";
import PropertyListImg1Mob from "../../../images/Search/propertysearchdesk1.jpg";

import PropertyListImg2Desktop from "../../../images/Search/propertysearchdesk2.jpg";
import PropertyListImg2Tab from "../../../images/Search/propertysearchdesk2.jpg";
import PropertyListImg2Mob from "../../../images/Search/propertysearchdesk2.jpg";

import PropertyListImg3Desktop from "../../../images/Search/propertysearchdesk3.jpg";
import PropertyListImg3Tab from "../../../images/Search/propertysearchdesk3.jpg";
import PropertyListImg3Mob from "../../../images/Search/propertysearchdesk3.jpg";

import PropertyListImg4Desktop from "../../../images/Search/propertysearchdesk4.jpg";
import PropertyListImg4Tab from "../../../images/Search/propertysearchdesk4.jpg";
import PropertyListImg4Mob from "../../../images/Search/propertysearchdesk4.jpg";

import PropertyListImg5Desktop from "../../../images/Search/propertysearchdesk5.jpg";
import PropertyListImg5Tab from "../../../images/Search/propertysearchdesk5.jpg";
import PropertyListImg5Mob from "../../../images/Search/propertysearchdesk5.jpg";

import PropertyListImg6Desktop from "../../../images/Search/propertysearchdesk6.jpg";
import PropertyListImg6Tab from "../../../images/Search/propertysearchdesk6.jpg";
import PropertyListImg6Mob from "../../../images/Search/propertysearchdesk6.jpg";

import PropertyListImg7Desktop from "../../../images/Search/propertysearchdesk7.jpg";
import PropertyListImg7Tab from "../../../images/Search/propertysearchdesk7.jpg";
import PropertyListImg7Mob from "../../../images/Search/propertysearchdesk7.jpg";

import PropertyListImg8Desktop from "../../../images/Search/propertysearchdesk8.jpg";
import PropertyListImg8Tab from "../../../images/Search/propertysearchdesk8.jpg";
import PropertyListImg8Mob from "../../../images/Search/propertysearchdesk8.jpg";

import PropertyListImg9Desktop from "../../../images/Search/propertysearchdesk9.jpg";
import PropertyListImg9Tab from "../../../images/Search/propertysearchdesk9.jpg";
import PropertyListImg9Mob from "../../../images/Search/propertysearchdesk9.jpg";

import PropertyListImg10Desktop from "../../../images/Search/propertysearchdesk10.jpg";
import PropertyListImg10Tab from "../../../images/Search/propertysearchdesk10.jpg";
import PropertyListImg10Mob from "../../../images/Search/propertysearchdesk10.jpg";

//enquiry image
import EnquiryImgDesktop1 from "../../../images/Search/enquiry-img1.png";
import EnquiryImgTab1 from "../../../images/Search/enquiry-img1.png";
import EnquiryImgMob1 from "../../../images/Search/enquiry-img1.png";

import EnquiryImgDesktop2 from "../../../images/Search/enquiry-img2.png";
import EnquiryImgTab2 from "../../../images/Search/enquiry-img2.png";
import EnquiryImgMob2 from "../../../images/Search/enquiry-img2.png";

import EnquiryImgDesktop3 from "../../../images/Search/enquiry-img3.png";
import EnquiryImgTab3 from "../../../images/Search/enquiry-img3.png";
import EnquiryImgMob3 from "../../../images/Search/enquiry-img3.png";

import EnquiryImgDesktop4 from "../../../images/Search/enquiry-img4.png";
import EnquiryImgTab4 from "../../../images/Search/enquiry-img4.png";
import EnquiryImgMob4 from "../../../images/Search/enquiry-img4.png";

//search-bottom-image
import SearchBottomImgDesktop from "../../../images/Search/search-bottom-desktop.jpg";
import SearchBottomImgTablet from "../../../images/Search/search-bottom-tablet.jpg";
import SearchBottomImgMobile from "../../../images/Search/search-bottom-mob.jpg";

const SearchResults = (props) => {
  const SearchList = [
    {
      propertyListImgMob: PropertyListImg1Mob,
      propertyListImgTab: PropertyListImg1Tab,
      propertyListImgDesk: PropertyListImg1Desktop,
      propertyCatTag: "Under Offer",
      propertyGalleryCount: "14",
      propertyTitle: "Dumont Road, London, N16",
      propertyPrice: "£1,575,000",
      propertySubTitle: "5 bedroom terraced house for sale",
      propertyDescription:
        "This magnificent home is in a league of its own. It has been refurbished from top to bottom (and then some) to an exemplary standard. Over 2,100 square feet, fabulous style, top quality finish and an incredible...",
      propertyDistace: "Rectory Road 0.3 miles",
      propertyDistace2: "Finsbury Park 0.8 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop1,
      propertyEnquiryImgTab: EnquiryImgTab1,
      propertyEnquiryImgMob: EnquiryImgMob1,
      propertyContactNumber: "0207 923 9222",
    },

    {
      propertyListImgMob: PropertyListImg2Mob,
      propertyListImgTab: PropertyListImg2Tab,
      propertyListImgDesk: PropertyListImg2Desktop,
      propertyCatTag: "",
      propertyGalleryCount: "12",
      propertyTitle: "Norcott Road, London, N16",
      propertyPrice: "£1,500,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "Join us for the Property Launch on Saturday 21st November 2020 - call now to secure your viewing slot! This stunning family home boasts beautiful interiors and a wonderful 54’ west-facing garden. Lovingly rem...",
      propertyDistace: "Rectory Road 0.4 miles",
      propertyDistace2: "Stoke Newington 1.2 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop2,
      propertyEnquiryImgTab: EnquiryImgTab2,
      propertyEnquiryImgMob: EnquiryImgMob2,
      propertyContactNumber: "0207 923 2730",
    },
    {
      propertyListImgMob: PropertyListImg3Mob,
      propertyListImgTab: PropertyListImg3Tab,
      propertyListImgDesk: PropertyListImg3Desktop,
      propertyCatTag: "",
      propertyGalleryCount: "19",
      propertyTitle: "Carysfort Road, London, N16",
      propertyPrice: "£1,450,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "Quietly located on one of Stokey’s most premier streets, within easy reach of Church Street, award-winning Clissold Park, and Newington Green, this excellent four bedroom family home spans almost 1,900 sq. ft,...",
      propertyDistace: "Rectory Road 0.3 miles",
      propertyDistace2: "Finsbury Park 0.8 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop3,
      propertyEnquiryImgTab: EnquiryImgTab3,
      propertyEnquiryImgMob: EnquiryImgMob3,
      propertyContactNumber: "0207 102 3049",
    },
    {
      propertyListImgMob: PropertyListImg4Mob,
      propertyListImgTab: PropertyListImg4Tab,
      propertyListImgDesk: PropertyListImg4Desktop,
      propertyCatTag: "SSTC",
      propertyGalleryCount: "11",
      propertyTitle: "Reighton Road, London, E5",
      propertyPrice: "£1,295,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "This magnificent home offers an incredible space to grow into and an exemplary finish throughout. It has been refurbished from top to bottom and offers the rare opportunity to purchase a grand home, beautifull...",
      propertyDistace: "Rectory Road 0.4 miles",
      propertyDistace2: "Stoke Newington 1.2 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop4,
      propertyEnquiryImgTab: EnquiryImgTab4,
      propertyEnquiryImgMob: EnquiryImgMob4,
      propertyContactNumber: "0207 911 2039",
    },
    {
      propertyListImgMob: PropertyListImg5Mob,
      propertyListImgTab: PropertyListImg5Tab,
      propertyListImgDesk: PropertyListImg5Desktop,
      propertyCatTag: "",
      propertyGalleryCount: "14",
      propertyTitle: "Dumont Road, London, N16",
      propertyPrice: "£1,575,000",
      propertySubTitle: "5 bedroom terraced house for sale",
      propertyDescription:
        "This magnificent home is in a league of its own. It has been refurbished from top to bottom (and then some) to an exemplary standard. Over 2,100 square feet, fabulous style, top quality finish and an incredible...",
      propertyDistace: "Rectory Road 0.3 miles",
      propertyDistace2: "Finsbury Park 0.8 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop1,
      propertyEnquiryImgTab: EnquiryImgTab1,
      propertyEnquiryImgMob: EnquiryImgMob1,
      propertyContactNumber: "0207 923 9222",
    },
    {
      propertyListImgMob: PropertyListImg6Mob,
      propertyListImgTab: PropertyListImg6Tab,
      propertyListImgDesk: PropertyListImg6Desktop,
      propertyCatTag: "SSTC",
      propertyGalleryCount: "12",
      propertyTitle: "Norcott Road, London, N16",
      propertyPrice: "£1,500,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "Join us for the Property Launch on Saturday 21st November 2020 - call now to secure your viewing slot! This stunning family home boasts beautiful interiors and a wonderful 54’ west-facing garden. Lovingly rem...",
      propertyDistace: "Rectory Road 0.4 miles",
      propertyDistace2: "Stoke Newington 1.2 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop2,
      propertyEnquiryImgTab: EnquiryImgTab2,
      propertyEnquiryImgMob: EnquiryImgMob2,
      propertyContactNumber: "0207 923 2730",
    },
    {
      propertyListImgMob: PropertyListImg7Mob,
      propertyListImgTab: PropertyListImg7Tab,
      propertyListImgDesk: PropertyListImg7Desktop,
      propertyGalleryCount: "19",
      propertyTitle: "Carysfort Road, London, N16",
      propertyPrice: "£1,450,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "Quietly located on one of Stokey’s most premier streets, within easy reach of Church Street, award-winning Clissold Park, and Newington Green, this excellent four bedroom family home spans almost 1,900 sq. ft,...",
      propertyDistace: "Rectory Road 0.3 miles",
      propertyDistace2: "Finsbury Park 0.8 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop3,
      propertyEnquiryImgTab: EnquiryImgTab3,
      propertyEnquiryImgMob: EnquiryImgMob3,
      propertyContactNumber: "0207 102 3049",
    },
    {
      propertyListImgMob: PropertyListImg8Mob,
      propertyListImgTab: PropertyListImg8Tab,
      propertyListImgDesk: PropertyListImg8Desktop,
      propertyGalleryCount: "11",
      propertyTitle: "Reighton Road, London, E5",
      propertyPrice: "£1,295,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "This magnificent home offers an incredible space to grow into and an exemplary finish throughout. It has been refurbished from top to bottom and offers the rare opportunity to purchase a grand home, beautifull...",
      propertyDistace: "Rectory Road 0.4 miles",
      propertyDistace2: "Stoke Newington 1.2 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop4,
      propertyEnquiryImgTab: EnquiryImgTab4,
      propertyEnquiryImgMob: EnquiryImgMob4,
      propertyContactNumber: "0207 911 2039",
    },
    {
      propertyListImgMob: PropertyListImg9Mob,
      propertyListImgTab: PropertyListImg9Tab,
      propertyListImgDesk: PropertyListImg9Desktop,
      propertyGalleryCount: "19",
      propertyTitle: "Walford Road, London, N16",
      propertyPrice: "£1,450,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "Quietly located on one of Stokey’s most premier streets, within easy reach of Church Street, award-winning Clissold Park, and Newington Green, this excellent four bedroom family home spans almost 1,900 sq. ft,...",
      propertyDistace: "Rectory Road 0.3 miles",
      propertyDistace2: "Finsbury Park 0.8 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop3,
      propertyEnquiryImgTab: EnquiryImgTab3,
      propertyEnquiryImgMob: EnquiryImgMob3,
      propertyContactNumber: "0207 102 3049",
    },
    {
      propertyListImgMob: PropertyListImg10Mob,
      propertyListImgTab: PropertyListImg10Tab,
      propertyListImgDesk: PropertyListImg10Desktop,
      propertyGalleryCount: "11",
      propertyTitle: "Foulden Road, London, N16",
      propertyPrice: "£1,450,000",
      propertySubTitle: "4 bedroom terraced house for sale",
      propertyDescription:
        "This magnificent home offers an incredible space to grow into and an exemplary finish throughout. It has been refurbished from top to bottom and offers the rare opportunity to purchase a grand home, beautifull...",
      propertyDistace: "Rectory Road 0.4 miles",
      propertyDistace2: "Stoke Newington 1.2 miles",
      propertyEnquiryImgDesk: EnquiryImgDesktop4,
      propertyEnquiryImgTab: EnquiryImgTab4,
      propertyEnquiryImgMob: EnquiryImgMob4,
      propertyContactNumber: "0207 911 2039",
    },
  ];
  return (
    <React.Fragment>
      <section className="search-results">
        <Container>
          <Row>
            {SearchList.map((item, index) => (
              <Col xl={6} className="mbot" key={index}>
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                  <div className="property-details">
                    <div className="property-image-block">
                      <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={item.propertyListImgDesk}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={item.propertyListImgTab}
                        />
                        <img src={item.propertyListImgMob} alt="" />
                      </picture>
                      <Link href="#" className="view-img">
                        <i className="icon-view-img"></i>
                        <span>{item.propertyGalleryCount}</span>
                      </Link>
                      <span className="category-tag">
                        {item.propertyCatTag}
                      </span>
                    </div>
                  </div>
                  <div className="property-content-block">
                    <h2>
                      <a href="#" className="btn-text-link">
                        {item.propertyTitle}
                      </a>
                    </h2>
                    <p className="amount">{item.propertyPrice}</p>
                    <p className="small-desc">{item.propertySubTitle}</p>
                    <p className="large-desc">{item.propertyDescription}</p>
                    <ul className="desc-list">
                      <li>
                        <i className="icon-list-property"></i>
                        {item.propertyDistace}
                      </li>
                      <li>
                        <i className="icon-list-property"></i>
                        {item.propertyDistace2}
                      </li>
                    </ul>
                    <div className="list-enquiry">
                      {/* <picture>
                        <source
                          media="(min-width:992px)"
                          srcSet={item.propertyEnquiryImgDesk}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={item.propertyEnquiryImgTab}
                        />
                        <img src={item.propertyEnquiryImgMob} alt="" />
                      </picture> */}
                      <p className="enquiry-text">
                        <span>
                          <Link href="#" className="border-bottom-yellow">
                            Book a Viewing
                          </Link>{" "}
                          {item.propertyContactNumber &&
                          <>
                           or call{" "}
                          <Link
                            className="border-bottom-yellow-slide"
                            href={`tel: ${item.propertyContactNumber}`}
                          >
                            {item.propertyContactNumber}
                          </Link>
                          </>
                          }
                         
                        </span>
                      </p>
                    </div>
                  </div>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
        <div className="container-custom">
          <div className="search-bottom-block">
            <ScrollAnimation
              animateIn="fadeInDown"
              className="search-bottom-image"
            >
              <div>
                <picture>
                  <source
                    media="(min-width:992px)"
                    srcSet={SearchBottomImgDesktop}
                  />
                  <source
                    media="(min-width:768px)"
                    srcSet={SearchBottomImgTablet}
                  />
                  <img src={SearchBottomImgMobile} alt="" />
                </picture>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInUp"
              className="search-bottom-content"
            >
              <h3>Take the stress out of moving home.</h3>
              <p>
                Quisque laoreet finibus quam, et efficitur lectus aliquet a.
                Integer a aliquet dui. Ut maximus, urna eget lacinia malesuada,
                felis magna ornare quam, vel lobortis.
              </p>
              <Link className="btn btn-yellow" href="#">
                Contact our team
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default SearchResults;
