import React,{useEffect, useState} from "react";
import {
    Button,
    Navbar,
    Nav,
    Form,
    Container,
    FormControl,
    Row,
    Col,
    dark,
  } from "react-bootstrap";

const MobileFilter =()=> {

    return (
        <React.Fragment>
                      <div className="mb-3">
            <h4>London</h4>
            <ul className="offer-list d-flex flex-wrap">
              <li>
                <Form.Check
                  custom
                  inline
                  label="Finsbury Park"
                  type="checkbox"
                  id="checkbox-1"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Highbury"
                  type="checkbox"
                  id="checkbox-2"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Stoke Newington"
                  type="checkbox"
                  id="checkbox-3"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Hackney"
                  type="checkbox"
                  id="checkbox-4"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Stamford Hill"
                  type="checkbox"
                  id="checkbox-5"
                />
              </li>
              <li>
                <Form.Check
                  custom
                  inline
                  label="Thetford"
                  type="checkbox"
                  id="checkbox-6"
                />
              </li>
            </ul>
            <h4>Norfolk</h4>
            <ul className="offer-list d-flex flex-wrap">
              <li>
                <Form.Check
                  custom
                  inline
                  label="Breckland"
                  type="checkbox"
                  id="checkbox-7"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Great Yarmouth"
                  type="checkbox"
                  id="checkbox-8"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="North Norfolk"
                  type="checkbox"
                  id="checkbox-9"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Broadland"
                  type="checkbox"
                  id="checkbox-10"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="King's Lynn"
                  type="checkbox"
                  id="checkbox-11"
                />
              </li>

              <li>
                <Form.Check
                  custom
                  inline
                  label="Norwich"
                  type="checkbox"
                  id="checkbox-12"
                />
              </li>
            </ul>
          </div>
            </React.Fragment>
    )
}

export default MobileFilter;