import React,{useState, useEffect, useRef} from "react";
import SearchFilter from "../SearchFilter/SearchFilter";
import OutsideAlerter from "../oustsideClick";

 const Menu =(props)=>{
    let ref = useRef();
    let refLink =useRef(null);
    const [showModal, setShowModal] = useState(false);

    
      const displayModal = () => {
        setShowModal(!showModal);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };

    return(
      <>
        <li
                  className={
                    showModal ? "active-tab dropdown_box" : "dropdown_box"
                  }
                >
                  <OutsideAlerter handleSubRoutine={closeModal}>
                    <strong ref={ref} onClick={displayModal}>
                      <span>{props.item}</span>{" "}
                      <i
                        className={
                          showModal
                            ? "icon-minus-yellow icon-plus-yellow"
                            : "icon-plus-yellow"
                        }
                      ></i>
                    </strong>
                    {showModal ? <SearchFilter /> : !showModal}
                  </OutsideAlerter>
                </li>  

                </>             
    )
 }

 export default Menu