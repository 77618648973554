import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
  dark,
} from "react-bootstrap";
import "./SearchBanner.scss";
import Menu from './Menu'
import MobileMenu from './MobileMenu'

const SearchBanner = (props) => {
  let ref = useRef();
  
  const [showModal, setShowModal] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);
  const [filterMobile, setFilterMobile] = useState(false);
  const [grid, setGrid] = useState(true);
  const [map, setMap] = useState(false);
  const [buttonUp, setButtonUp] = useState(false);
  const [state, setState] = useState(["Buy","Location", "Price Range", "Bedrooms", "Type"])
  const [newState, setNewState] = useState([{name:"Buy",isActive:false},{name:"Location", isActive:false}, {name:"Price Range", isActive:false}, {name:"Bedrooms", isActive:false}, {name:"Type", isActive:false}])

  const mobileSideShow = () => {
    setMobileShow(!mobileShow);
  };

  const showGrid=()=>{
    setMap(false)
    setGrid(true)
  }

  const showMap=()=>{
    setMap(true)
    setGrid(false)
  }

  const button=(e)=>{
    setButtonUp(!buttonUp)
  }

  const filterhandler = (i) => {
    let newState1 = [...newState]
    let b = newState1.map((item,index)=>{
      if(index===i){
        return {...item,isActive:!item.isActive}
      }else{
        return {...item,isActive:false}
      }      
    })
    setNewState(b)
  }

  return (
    <React.Fragment>
      <section className="search-banner">
        <Container>
          <ScrollAnimation animateOnce={true} duration={0.5} className="banner-animated" animateIn="fadeInUp">
            <div className="search-banner-content">
              <h4>141 Properties for Sale in London</h4>
              <h1>Beautiful. London. Property.</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent non nisi dui. Phasellus porttitor ex et purus dapibus
                interdum donec pharetra tempor.
              </p>
            </div>
            <div className="button-search-box">
              <Button variant="dark d-xl-none" onClick={mobileSideShow}>
                <span className="d-md-none">Refine</span>
                <span className="d-none d-md-block">Refine Search</span>
                <i className="icon-plus-menu"></i>
              </Button>
              <ul className="d-none d-xl-block">
                {
                  state.map(item=>
                    <Menu item={item}/>
                  )
                }
              </ul>
              <div className="banner-buttons">
                <div className="switch-button d-none d-xl-block">
                  <label for="toggle-button">
                    <input type="checkbox" id="toggle-button" />
                    <span className="toggle-switch"></span>
                    <span className="toggle-text">Include Sold</span>
                  </label>
                </div>
                <button type="button" className="toggle-button">
                  <span onClick={showGrid} className={!grid ? "inactive-btn toggle-icon" : "active-btn toggle-icon"}>
                    <i class="icon-grid"></i>
                  </span>
                  <span onClick={showMap} className={map ? "active-btn toggle-icon" : "inactive-btn toggle-icon"}>
                    <i class="icon-map"></i>
                  </span>
                </button>
                <Button ref={ref} variant="dark" onClick={e=>button()} className={buttonUp ? "button-filter filter-active-btn" : "button-filter"}>
                  <i className="icon-filter"></i>
                </Button>
              </div>
            </div>
          </ScrollAnimation>
        </Container>
      </section>
      <div className={mobileShow ? "filter-bar open-filterbar" : "filter-bar "}>
        <div className="btn-close-menu">
          <button
            type="button"
            className="close-btn"
            onClick={(e) => (setMobileShow(false), setFilterMobile(false))}
          >
            <i className="icon-close"></i>
          </button>
        </div>
        <div className="filter-items">
          <ul>
          {
                  newState.map((item,i)=>
                    <MobileMenu item={item} i={i} filterhandler={filterhandler}/>
                  )
                }
          </ul>
          <div className="button-update">
            <a href="#" className="btn btn-yellow">
              Update
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchBanner;
