import React,{useState, useRef} from "react";
import OutsideAlerter from "../oustsideClick";
import MobileFilter from "./MobileFilter";

const MobileMenu=(props)=>{

    return(
        
        <li className="dropdown_box">
                  <OutsideAlerter>          
        <span
          className={
            props.item.isActive ? "active-tab filter-link" : "filter-link"
          }
          onClick={()=>props.filterhandler(props.i)}
        >
          <a href="#">{props.item.name}</a>
          <i
            className={
              props.item.isActive
                ? "icon-minus-yellow icon-plus-yellow"
                : "icon-plus-yellow"
            }
          ></i>
        </span>
        </OutsideAlerter>
        <div
          className={
            props.item.isActive ? "dropdown-exp filter-visible" : "dropdown-exp"
          }
           
        >
          {props.item.isActive ? <MobileFilter /> : null}
        </div>
      </li>
    )
}

export default MobileMenu